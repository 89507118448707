import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

import { ErrorMessage, Form, Formik } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'
import { FieldWithErrorMessageAndLabel } from '../components/shared'

const initialFormValues = {
  password: ''
}

const Rsvp = ({ pageContext }) => {
  const passwordRegex = pageContext.passwordRegex;
  const greekPasswordsRegex = pageContext.greekPasswordsRegex;
  const validationSchema = Yup.object({
    password: Yup
      .string()
      .transform((value, originalValue) => {
        return value.toLowerCase().trim()
      })
      .required("Password is required")
      .matches(RegExp(passwordRegex), "Your password is incorrect, please try again")
  })
  return (
    <Layout>
      <Helmet>
        <title>RSVP</title>
      </Helmet>
      <div id="main">
        <h1>RSVP</h1>
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={false}
          onSubmit={(values) => {
            const password = values.password.toLowerCase().trim().replace(" ", "-");
            console.log(!!password.match(RegExp(greekPasswordsRegex)))
            if (!!password.match(RegExp(greekPasswordsRegex))) {
              window.location = `/el/rsvp-response/password/${password}`
            }
            else {
              window.location = `/rsvp-response/password/${password}`
            }
          }}>
          <Form name="passwordForm">
            <FieldWithErrorMessageAndLabel
              name="password"
              type="text"
              message="Please enter your password / Παρακαλώ εισάγετε τον κωδικό σας"
              onBlur={ (e) => {
                e.currentTarget.value = e.currentTarget.value.toLowerCase();
              }}
              />
            <button type="submit">Submit</button>
            <p></p>
            <p>If you have any issues with the website, please send your RSVP to <a href={'mailto:rsvp@jakeandhara.com'}>rsvp@jakeandhara.com</a></p>
            <p>Αν έχετε πρόβλημα με το website, μπορείτε να στείλετε το RSVP σας με email στο <a href={'mailto:rsvp@jakeandhara.com'}>rsvp@jakeandhara.com</a></p>
          </Form>
        </Formik>
      </div>
    </Layout>
  )
}

const StyledError = styled.div`
  color: red;
`
export default Rsvp
